.headerSection {
  background-color: #d4b8b8 !important;
  padding: 21px 0;
  width: Calc(100% - 280px) !important;
  height: 80px  !important;
  box-shadow: none;
}

.headerSection {
  display: flex;
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

.mainInner {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.dashbord-one {
  margin-top: 18px;
}

.dashBoard__title{
  color: #333333;
  font-weight: 500;
  font-size: 28px;
  // padding-left: 9px;
}
.input-border{
  border: 1px solid #D2D2D4;
}
.main svg{
  color: #ababab;
}
.main input{
  color: black;
}
.right-header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.user-id{
display: flex;
  align-items: center;
}
.user-name{
  text-align: right;
  margin-right: 11px;
}
.user-name p{
  font-size: 11px;
  font-weight: 600;
  color: #333;
  margin: 0;
  padding: 0;
}
.user-name span{
  font-size: 10px;
  font-weight: 400;
  color: #333;
}
.export_csv{
  height: 1em;
}

.notification{
  padding-left: 18px;
}

.user-id{
  padding-left: 30px;
}

.logout{
  padding: 0px 12px;
}