.header{
  position: fixed;
  height: 60px;
  width: 100%;
  background-color: #333333;
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  z-index: 1;

  .logo{
    width: 280px;
    margin: 12px 10px;
  }
}

.forgot-password{
  position: relative;
  width: 100%;
  min-width: 800px;
  min-height: 100%;
  background-image: url('../assets/images/background.jpg');
  background-size: cover;
  background-position: center;

  .content{
    width: 90%;
    min-height: max(calc(90vh - 90px), 500px);
    padding: 90px 5% 30px 5%;
  
    .banner{
      position: absolute;
      width: 82%;
      height: 52%;
      top: 26%;
      left: 8%;
      padding: 0;
      background-color: rgb(51, 51, 51);
      border-radius: 3px;

      .banner-internal{
        width: 44%;
        padding: 5% 0 0 53%;

        .logo{
          width: 100%;
          margin-bottom: 1.5em;
        }

        .logo IMG{
          min-width: 80px !important;
          max-width: 20% !important;
        }

        .title{
          font-size: 2.15rem;
          font-family: inherit;
          font-weight: 500;
          letter-spacing: 1px;
          color: white;
          margin: 0.25em 0
        }

        .subtitle{
          font-size: 1.1rem;
          letter-spacing: 1px;
          color: white;
        }

        .action{
          margin: 3em 0 0 0;
        }
      }
    }

    .form{
      position: relative;
      width: 40%;
      min-height: max(calc(90vh - 90px), 500px);
      margin: 0 52% 0 8%;
      background-color: white;
      border-radius: 3px;
    }
  }

  .forgot-password-form {
    position: relative;
    width: 82%;
    padding: 13% 9%;
  
    .title {
      font-size: 2rem;
      letter-spacing: 1px;
      color: rgb(111, 111, 111);
      padding-bottom: 0.5rem;
    }
  
    .subtitle {
      font-size: 1rem;
      letter-spacing: 1px;
      color: rgb(111, 111, 111);
      padding-bottom: 1rem;
    }

    .inputs-fields{
      margin-bottom: 1rem;
    }
  
    .forgot-password-error {
      color: red;
      padding: 0em 1em 1em 1em;
      font-size: 0.8em;
    }
  
    .remember {
      margin-bottom: 2rem;
  
      .checkbox-remember {
        padding: 0;
      }
  
      &__text {
        font-size: 0.8rem;
        padding: 5.5px;
        letter-spacing: 1px;
      }
    }
  
    .submit__forgot{
      cursor: pointer;

      &__text{
        color: rgb(111, 111, 111);
      }
    }
  
    .submit__row {
      padding-bottom: 2em;

      a {
        text-decoration: none;
        letter-spacing: 1px;
        font-size: 0.8rem;
      }
  
      .submit__btn {
        float: right;
        margin-top: 2em;
      }
    }
  } 
}