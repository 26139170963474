.custom-modal, .prompt-modal{
  min-height: max(70%, 450px);

  .internal-shifted-left{
    left: calc(280px + (max(100% - 280px, 500px)) * 0.5) !important;
  }

  .internal{
    position: absolute;
    top: 50%;
    left: calc((max(100%, 500px)) * 0.5);
    width: calc(min(75%, 900px));
    min-width: 500px;
    min-height: min(20vh, 150px);
    max-height: 85vh;
    padding: 0;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 5px;
    outline: none;
    overflow: hidden;
    background-color: #ffffff;

    &:active, &:focus {
      outline: none;
    }

    .modal-header {
      background-color: #333333;
      width: 100%;
      height: 3em;
      display: flex;
      align-items: center;

      .title {
        width: 90%;
        color: white;
        font-size: 1.25em;
        text-align: left;
        padding: 2px 1em;
      }
      .close {
        display: flex;
        width: 10%;
        height: 100%;
        padding: 2px 1em;
        align-items: center;
        justify-content: flex-end;

        .modal-close-button {
          padding: 0;
          margin: 0;
          --padding-start: 0;
          --padding-end: 0;
          cursor: pointer;
        }
      }
    }

    .modal-body {
      position: relative;
      width: 100%;
      height: calc(100% - 3em);
      max-height: calc(85vh - 3em);
      overflow-y: auto;
    }

    .modal-body-internal {
      padding: 1em 2em;
    }

    .modal-actions{
      padding: 1em 0 0 0;
      text-align: center;

      .modal-button{
        background: transparent;
        border: 1px solid #333333;
        border-radius: 5px;
        padding: 5px 10px;
        text-transform: uppercase;
        margin: 0 0.5em;
        cursor: pointer;
      }

      .modal-button-confirm{
        background-color: #077764;
        color: white;
      }
    }
  }

}

.prompt-modal{
  .internal{
    width: 45% !important;

    .modal-body{
      text-align: center;
    }
  }  
}
