.organization-main-wrp{position: relative;padding: 28px;}
.organization-wrp-title{padding-bottom: 10px;}
.organization-content-row{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.organization-content-col-12{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.organization-content-col-8{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 66.666666%;
  flex: 0 0 66.666666%;
  max-width: 66.666666%;
}
.organization-content-col-7{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.organization-content-col-6{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.organization-content-col-4{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.organization-content-col-3{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.organization-content-col-2{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.organization-content-col-1{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.organization-content-col-flex{
  display: flex;
  align-items: center;
}

.org-form-box{width: 100%;padding-bottom: 20px;}
.org-form-box label {
    width: 100%;
    display: inline-block;
    color: #333333;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
}
.org-form-box input, .org-form-box select {
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    padding: 7px 16px;
}
.check-boxs label{
    font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
color: #333333;
margin: 0;
}
.check-boxs label input {
    display: inline-block;
    text-align: left;
    width: 16px;
    height: 16px;
    margin-right: 7px;
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: sub;
}
.add-user-pic label{font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #077764;
    margin: 0;
}
.organization-content-footer{
    text-align: right;
}
.organization-content-footer button {
    background: #077764;
    border-radius: 4px;
    padding: 5px 23px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
.organization-content-footer button:hover {background: #077764;color: #fff;}

@media only screen and (max-width: 991px) {
    .license-modal-inner{width: 100%;}
}
@media only screen and (max-width: 767px) {
.organization-content-col-2,
.organization-content-col-3,
.organization-content-col-4,
.organization-content-col-6{
-ms-flex: 0 0 100%;flex: 0 0 100%;max-width: 100%;
}
}