.Allselect > div {
  padding: 7px 12px;
  font-size: 13px;
  border: 1px solid #d2d2d4;
  color: #333333;
  .Mui-selected {
    background-color: #f9f9f9 !important;
  }
}

.Icon_Button{
     svg {
    fill: #BABABB;
  }
}
.license-table-title{
    /* padding-bottom: 10px; */
    /* padding-left: 10px; */
    margin: 1em 0 0 1.6em;
    color: #333333;
}

.first-cell{
  text-align: left !important;
  color: #6F6F70 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  font-weight: bold !important;
}
.all-cell{
  text-align: center !important;
  color: #6F6F70 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding:0 16px !important;
}
.MuiMenu-list{
  .Mui-selected {
    background-color: #f9f9f9 !important;
  }
}
.table-heading > th {
  color: #767676;
  font-size: 14px;
}
.main-table .License_table {
  padding-top: 0;
}
.border {
  border: 2px solid #2e7764 !important;
}
.risk {
  padding: 15px 5px;
}
.con-button {
  display: flex;
  align-items: center;
  margin: 12px 0px;
}
.con-button button {
  height: 40px;
  background: transparent;
  border-radius: 5px 0 0 5px;
  width: 23px;
  padding: 0;
}
.con-button input {
  width: 40px !important;
  height: 40px;
  text-align: center;
}
.con-button input::focus-visible {
  border-color: green !important;
}
.right-button {
  border-radius: 0 5px 5px 0 !important;
}
.save-button {
  display: flex;
  align-items: center;
}
.save-button button {
  color: green;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.con-button button {
  border: 1px solid #BABABB;
  border-right: 
0;
}

.con-button input {
  border: 1px solid #BABABB;
}

button.right-button {
  border-left: 0 !important;
  border-right: 1px solid #BABABB!important;
}

.con-button button svg {
  fill: #BABABB;
}


.con-button input:focus {outline: none;border: 2px solid #077764;}