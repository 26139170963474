.organization{
  width: 96% !important;
  margin-left: auto !important;
}

.organizations-list{

  .organizations-summary{
    .MuiAccordionSummary-content{
      line-height: 2.5em;
    }

    .MuiGrid-item{
      line-height: 1.5em;
    }

    .organizations-summary-grid{
      margin-top: -8px !important;
    }

    .list-organization-name{
      font-weight: 600;
      color: #000000;
    }

    .list-organization-common {
      font-size: 0.9em;
    }
  }

  .organizations-details{
    .license-details-error{
      display: block;
      text-align: center;
    }

    .license-details-retry{
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 0.5em 0;
    }

    .main-grid{
      margin-left: 1%;
      width: 99%;
    }

    .section_title{
      font-size: 10px;
      color: #bababa;
      text-transform: uppercase;
    }

    .section_message{
      text-align: center;
      padding: 1em 0;
    }

    .section_spacer{
      height: 1.5em;
    }

    .section_line{
      width: 90%;
      margin: 1em 0;
      background-color: #e5e5e5;
      height: 1px;      
    }

    .section_document{
      font-size: 12px !important;
      font-weight: 600;
      margin: 0.5em 0;
    }

    .notes-list{
      width: 90% !important;
      .notes-date{
        font-weight: 600;
        align-items: center;
        display: flex;
      }
      .notes-content{
        text-align: justify;
        align-items: center;
        display: flex;
      }
      .notes-actions{
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;

        .table-actions{
          color: #666666;
        }
        .table-actions-disabled{
          margin: 0.25em;
          cursor: pointer;
          color: #CBCBCB !important;
        }
      }
    }

    .documents-list{
      width: 90% !important;
      .documents-content{
        font-size: 13px;
        font-weight: 600;
        text-align: justify;
        align-items: center;
        display: flex;
      }
      .documents-actions{
        text-align: center;
      }
    }

    .attachments{

      .add-attachment-button{
        margin: 0.5em 0 0 0;
      }
    }
  }
}