.file-upload-main{
  position: relative;
  width: 100%;

  .file-upload-title {
    width: 100%;
    height: 1.5em;

    font-size: 14px;
    color: #333333;
  }

  .file-upload-container {
    width: 95%;
    margin: 0;
    margin-top: 5%;

    .file-upload-area {
      width: 100%;
      margin: 0;
      border: 1px dashed #333333;
      border-radius: 10px;
      padding: 1em;
      height: 8em;
      display: flex;
      align-items: center;

      .file-upload-area-section{
        width: 100%;
      }
      .file-upload-area-elements{
        width: 100%;
      }
    }

    .file-upload-area-texts{
      width: 100%;
    }

    .file-upload-error{
      font-size: 13px;
      color: #ff4444;
    }
    .file-upload-message{
      font-size: 13px;
      cursor: pointer;
      margin: 0.25em 0;
    }
    .file-upload-filename{
      font-size: 14px;
      font-style: italic;
      cursor: pointer;
      margin: 0.25em 0;
    }

    .file-upload-buttons{
      width: 90%;
      margin: 0.5em 5%;
      display: flex;
      justify-content: center;
    }

    .file-upload-extras{
      height: auto !important;
      padding: 0 !important;
      padding-top: 0.25em !important;

      .max-upload-span{
        font-size: 13px;
      }

      .file-upload-file-name{
        margin: 0.5em 0;
        font-size: 11px
      }
    }
  }
}