.dashboard{
  width: 96% !important;
  margin-left: auto !important;
}

button.add_client {
  background-color: #317f64;
  color: #fff;
  border: none;
  padding: 10px 25px;
  margin-top: 10px;
  border-radius: 5px;
}

.add_client_button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.dashbord-one{
    margin-top: 50px;
    // margin-left: auto !important;
    // margin-top: 54px !important;
}
.main-table{
  border: 1px solid #D2D2D4;
    border-radius: 5px;
    padding: 0 18px 18px 18px;
    background-color: #fff;
}
.child-table{
  display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D4D4D4;
    padding: 10px 0px;
    margin: 0 0 17px 0;
}
.child-table button{
  background: transparent;
    border: 1px solid #333333;
    border-radius: 5px;
    padding: 5px 10px;
}
.child-table div{
  color: #333;
}
.table-heading{
  background: #F9F9F9;
}
.table-heading th{
  padding: 10px;
  font-weight: 300;
}
.table-cell-one{
  font-weight: 600 !important;
}
.table-license span{
  background: #1A8B2533;
    border-radius: 100px;
    padding: 5px 11px;
  color: #1A8B25;
}
.yallow{
  background: #FFF3B7;
  color: #B98626;
}
.table-days{
  font-weight: 500 !important;
}
.logo-table svg{
  color: #BABABB !important;
  margin: 0 3px !important;
}
.dashBoard__title{
  color: #333333;
  font-weight: 500;
  font-size: 28px;
  // padding-left: 8px;
  margin-top: 25px;
}
.bar-1{
  color: #1A8B25 !important;
}
.logo-table {
  text-align: center !important;
}
.modal-header {
  background-color: #333333;
}
.license-modal-inner {
  width: 800px;
  box-sizing: border-box;
}
.modal-header h2 {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 9px 28px;
  position: relative;
  font-weight: normal;
}

.modal-header h2 svg {
  color: #fff;
}

.modal-header h2 button {
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  right: 17px;
}

.license-content-top {
  padding-bottom: 1em;
}

.license-content-top-row {
  display: flex;
  margin: 0 -20px;
}

.lct-form-box {
  width: 50%;
  padding: 0 20px;
}

.license-modal-inner * {
  box-sizing: border-box;
}

.lct-form-box label {
  width: 100%;
  display: inline-block;
  color: #333333;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}

.lct-form-box input, .lct-form-box select {
  background: #FFFFFF;
  border: 1px solid #D2D2D4;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 7px 16px;
}

.lct-form-box input::placeholder {
  color: #333333;
}

.license-content-bottom {
  padding: 1em 0;
  text-align: right;
}

.license-content-bottom button {
  background: #077764;
  border-radius: 4px;
  padding: 5px 23px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.license-content-middle > label {
  width: 100%;
  display: inline-block;
  color: #333333;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}

.license-content-middle {
  padding: 0;
}

.license-content-middle .license-content-table {
  border: 1px solid #D2D2D4;
  border-radius: 4px;
}
.license-content-middle .license-content-table th {
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  padding: 11px 12px;
}
.license-content-middle .license-content-table td {
  font-size: 12px;
  line-height: 18px;
  color: #6F6F70;
  padding: 11px 12px;
}
.license-content-middle .license-content-table td .MuiRadio-root {
  filter: brightness(0.1);
  padding: 0 13px;
}
.license-content-middle .license-content-table th:nth-child(4), .license-content-middle .license-content-table td:nth-child(4), .license-content-middle .license-content-table th:nth-child(5), .license-content-middle .license-content-table td:nth-child(5), .license-content-middle .license-content-table th:nth-child(6), .license-content-middle .license-content-table td:nth-child(6) {
  text-align: center;
}