.button-login{
  cursor: pointer;
  border: none;
  background-color: #077764 !important;
  color: #ffffff !important;
  margin: 0 0.2em !important;
  font-weight: 500;

  &:hover {
    background-color: #079c83 !important;
    color: #ffffff !important;
    border: none;
  }

  &:active, &:focus {
    background-color: #055749 !important;
    color: #ffffff !important;
    border: none;
  }
}
.button-login-disabled{
  background-color: #bbbbbb !important;
  color: #999999 !important;
}

.button-outline-login{
  cursor: pointer;
  border: 1px solid #077764 !important;
  background-color: transparent !important;
  color: #077764 !important;
  margin: 0 0.2em !important;

  &:hover {
    border: 1px solid #079c83 !important;
    background-color: #dfdede !important;
    color: #079c83 !important;
    border: none;
  }

  &:active, &:focus {
    border: 1px solid #055749 !important;
    background-color: #dfdede !important;
    color: #055749 !important;
    border: none;
  }
}
.button-outline-login-disabled{
  background-color: #bbbbbb !important;
  color: #999999 !important;
}

.button{
  cursor: pointer;
  border: none;
  background-color: #077764 !important;
  color: #ffffff !important;
  margin: 0 0.2em !important;
  font-weight: 600 !important;

  &:hover {
    background-color: #079c83 !important;
    color: #ffffff !important;
    border: none;
  }

  &:active, &:focus {
    background-color: #055749 !important;
    color: #ffffff !important;
    border: none;
  }
}
.button-disabled{
  background-color: #999999 !important;
  color: #999999 !important;
}

.button-outline{
  cursor: pointer;
  border: 2px solid #333333 !important;
  background-color: transparent !important;
  color: #333333 !important;
  margin: 0 0.2em !important;
  font-weight: 600 !important;

  &:hover {
    border: 2px solid #000000 !important;
    background-color: #dddddd !important;
    color: #000000 !important;
    border: none;
  }

  &:active, &:focus {
    border: 2px solid #000000 !important;
    background-color: #dddddd !important;
    color: #000000 !important;
    border: none;
  }
}
.button-outline-disabled{
  color: #999999 !important;
}

.align-center {
  text-align: center !important;
}

.sidebarSection > div {
  background-color: #232323;
}

.sidebarSection{
  .MuiDrawer-paper{
    overflow: hidden;
    width: 280px !important;
  }

  .headerSection {
    background-color: #363636 !important;
    padding: 21px 0;
    width: Calc(100% - 240px) !important;
  }
  .sidebarSectionInner {
    width: 320px !important;
    overflow: hidden !important;
  }
  .headerLogo {
    width: 220px;
    margin-left: 8px;
  }
  .menuIcon {
    min-width: auto;
    padding: 0 15px;
  }
  .menuItem{
    width: 254px !important;
    margin: 6px 0 !important;
    margin-left: 11px !important;
    border-radius: 5px;
  }
  .menuItem:hover {
    background: #8b8b8b !important;
  }
  .menuItemSelected{
    background: #5b5b5b !important;
  }
  .menuItemSelected:hover {
    background: #5b5b5b !important;
  }
  .menuIcon svg {
    color: #fff; 
  }
  .menuText {
    padding: 5px 12px;
    margin: 0 !important;
    color: #fff;
  }
  .menuText .MuiListItemText-primary{
    font-weight: 300 !important;
  }
  .menuTextSelected .MuiListItemText-primary{
    font-weight: 600 !important;
  }

  .help_button {
    margin: 18px;
    border-radius: 5px;
    padding: 5px 0;
    width: calc(100% - 36px);
    color: #E5E5E5 !important;
    border: 2px solid #E5E5E5 !important;
    text-align: center;
    display: flex;
    bottom: 0;
    position: absolute;
    justify-content: center;
  }
  .headerLogo {
    margin: 25px auto 35px;
    display: block;
    text-align: center;
    height: 40px;
    width: 200px;
  }
}

.main-section{
  margin-top: calc(max(8vh, 1em)) !important;

  .main-table{
    border: 1px solid #D2D2D4;
    border-radius: 5px;
    padding: 0;

    .table-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D4D4D4;
      padding: 10px 18px;
      margin: 0;
      font-weight: 600;

      .title{
        width: 50%;
        display: flex;
        text-align: center;
        justify-content: flex-start;
      }
      .actions{
        width: 50%;
        display: flex;
        text-align: center;
        justify-content: flex-end;
      }
    }

    .table-filter{
      margin: 0;
      padding: 1em 1em 0 1.25em;

      .table-filter-column{
        display: inline-flex;
        padding: 0;
      }
    }

    .table-content{
      border: 1px solid #d4d4d4;
      border-radius: 5px;
      margin: 1em 1.25em;
      padding: 0;
      overflow: hidden;

      .table-header{
        padding: 1em 1.25em;
        background-color: #f7f7f7;
        font-size: 11px;
        font-weight: 500;
        color: #767676;
        border-bottom: 1px solid #d4d4d4;
      }

      .table-loading{
        padding: 1em 1.25em;
        background-color: #f7f7f7;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }

      .table-loading-no-flex{
        padding: 1em 1.25em;
        background-color: #f7f7f7;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        height: 50vh;
      }
    }

    .main-button {
      background: transparent;
      border: 1px solid #333333;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
    }

    .child-table{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D4D4D4;
      padding: 10px 0px;
      margin: 0 0 17px 0;

      &__no-margin{
        margin: 0 !important;
      }
    }

    .child-table div{
      color: #333;
    }

    .table-heading{
      background: #F9F9F9;
    }
    .table-heading th{
      padding: 5px;
      font-weight: 300;
      text-align: center;
    }

    .table-cell-loading{
      font-style: italic;
      text-align: center;
      height: 10em;
    }

    .table-cell{
      padding: 10px;
    }

    .table-cell-no-padding{
      padding: 0;
    }

    .table-cell-one{
      font-weight: 600 !important;
    }

    .table-cell-one .pill, .table-cell .pill{
      margin: 0 3px;
      padding: 5px 11px;
      color: #1A8B25;
      background-color: #1A8B2533;
      border-radius: 100px;
    }

    .pill{
      display: inline-block;
      margin: 0 3px;
      padding: 5px 11px;
      border-radius: 100px;
      color: #1A8B25 !important;
      background-color:rgba(26, 139, 37, 0.2) !important;
    }

    .pill-green{
      color: #f3f3f3 !important;
      background-color: #078d14ab !important;
    }

    .pill-yellow{
      color: #252525 !important;
      background-color: #d1d112ab !important;
    }

    .pill-red{
      color: #f3f3f3 !important;
      background-color: #001402ab !important;
    }

    .table-actions{
      .MuiIconButton-root{
        padding: 2px;
      }
      .MuiSvgIcon-root {
        font-size: 22px;
      }
    }

    .table-actions svg{
      color: #666666 !important;
      margin: 0 3px !important;
    }
    .table-actions-disabled svg{
      color: #BABABB !important;
      margin: 0 3px !important;
    }
  }
}
.sidebarSection .MuiDrawer-paper{
    overflow: hidden;
    width: 280px !important;
}
.sidebarSection{
    width: 280px !important;
}
main.main_data_wrp {
    padding: 0 50px 40px 50px;
    background-color: #E5E5E5;;
    height: 100vh;
}

.label-clickable{
  cursor: pointer;
}

.main-form{
  width: 100%;

  .form-title{
    font-weight: 600 !important;
    margin-bottom: 0;
    margin-bottom: 1em;
  }

  .form-body{
    width: 100%;

    input.MuiOutlinedInput-input{
      padding: 10px !important;
    }
    input.MuiSelect-select{
      padding: 10px !important;
    }
    .MuiSelect-select{
      padding: 12px !important;
    }
  
    .form-label{
      line-height: 1em;
      font-size: 0.8em;
    }
  
  }

  .form-alert-error{
    .MuiAlert-icon {
      padding: 0 !important;
    }
    .MuiAlert-message {
      padding: 1px 0 !important;
    }
  }

  .form-error{
    display: flex;
    align-items: center;
    font-size: 0.8em;
    color: rgb(150, 40, 40);
  }

  .form-actions{
    text-align: right;

    .form-button{
      display: inline-block;
      margin: 0 0.5em;
    }
  }
}

.red{
  color:rgb(150, 40, 40);
}

.green{
  color: rgb(50, 130, 50)
}

.main-section.state-page .main-table {
  padding: 0;
}

.main-section.state-page .main-table .table__header {
  padding: 0 15px;
  height: 100%;
  max-height: 70px;
}

.Mui-selected {
  background-color: #5b5b5b !important;
}

header {
  position: absolute !important;
}

.fs-11{
  font-size: 11px !important;
}

.fs-12{
  font-size: 12px !important;
}

.fs-13{
  font-size: 13px !important;
}

.fs-14{
  font-size: 14px !important;
}

.fs-15{
  font-size: 15px !important;
}

.no-border{
  border: none !important;
}

.bold{
  font-weight: 500;
}

.centered{
  text-align: center;
}

.uppercase{
  text-transform: uppercase;
}

.show-pointer{
   cursor: pointer;
}

.no-select{
  user-select: none;
  -moz-user-select: none;
}